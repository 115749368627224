#jdi-header {
  position: relative;
  z-index: 1000;
}

#jdi-header svg,
#jdi-header img {
  display: inline;
}

#jdi-header .header {
  min-width: 1088px;
  background: #fff;
  box-shadow:
    0 2px 12px 0 #0000000a,
    0 4px 6px 0 #0000000f;
}

#jdi-header.home .header {
  background: var(--sys-color-Primary700);
  position: relative;
}

#jdi-header.home .header {
  box-shadow: none;
}

#jdi-header .w {
  margin: 0 auto;
  min-width: 1008px;
  max-width: min(1866px, calc(100% - 80px));
}

#jdi-header .header-main-wrap {
  padding-top: 14px;
  padding-bottom: 16px;
}

#jdi-header .header-main {
  height: 40px;
  display: flex;
}

#jdi-header .header-main .logo {
  margin-right: 40px;
  position: relative;
  top: 4px;
}

#jdi-header .header-main .logo img {
  width: 170px;
  height: 40px;
}

#jdi-header .header-main .deliver {
  margin-right: 40px;    
  display: flex;
  align-items: center;
}

#jdi-header-main-container {
  flex: 1;
  display: flex;
}

#jdi-header .deliver-box {
  display: flex;
  color: var(--sys-Text-Primary);
  text-align: center;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
}

#jdi-header.home .deliver-box {
  color: #fff;
  border-radius: 4px;
}

#jdi-header .deliver-box:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header.home .deliver-box:hover {
  color: #ffffffcc;
}

#jdi-header .deliver-box .deliver-icon {
  vertical-align: -3px;
  margin-right: 6px;
}

#jdi-header .deliver .deliver-arrow {
  margin-left: 2px;
}

#jdi-header .deliver .country {
  font-size: 16px;
}

#jdi-header .search-bar {
  flex: 1;
  height: 40px;
  border-radius: 6px;
  margin-right: 40px;
  display: flex;
  position: relative;
  border: 1px solid #C1C1C1;
}

#jdi-header.home .search-bar {
  background: var(--sys-background-White);
  border: 1px solid var(--sys-background-White);
}

#jdi-header .search-bar.active {
  border: 1px solid var(--sys-color-Primary800);
}

#jdi-header .search-bar:active,
#jdi-header .search-bar:hover {
  border: 1px solid var(--sys-color-Primary800);
}

#jdi-header.home .search-bar:active,
#jdi-header.home .search-bar:hover {
  border: 1px solid var(--sys-Text-Primary);
}

#jdi-header .search-bar .search-recommended-dropdown-layer {
  display: none;
}

#jdi-header .search-bar.active .search-recommended-dropdown-layer {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  z-index: 2000;
  top: 50px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  left: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow:
    0 6px 56px 0 #0000000a,
    0 12px 35px 0 #0000000f;
  padding: 8px 16px;
  display: block;
}

#jdi-header .search-recommended-dropdown-layer .search-view {
  position: relative;
}

#jdi-header .search-recommended-dropdown-layer .search-view .suggestWordItem {
  margin-top: 16px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
#jdi-header .search-recommended-dropdown-layer .search-view .suggestWordItem:hover{
  color: var(--sys-color-Primary800);
}
#jdi-header .search-recommended-dropdown-layer .search-view .suggestWordItem:hover mark{
  color: var(--sys-color-Primary800);
}
#jdi-header .search-recommended-dropdown-layer .search-view .suggestWordItem mark{
  color: #CC0C1C;
  background-color: #FFFFFF;
}

#jdi-header .search-recommended-dropdown-layer .search-view .title {
  padding: 8px 0;
  font-weight: 700;
}

#jdi-header .search-recommended-dropdown-layer .search-view .tags {
  overflow: hidden;
  font-size: 0;
}

#jdi-header .search-recommended-dropdown-layer .search-view .tags .tag {
  font-size: 14px;
  display: inline-block;
  padding: 5px 12px;
  background: #f7f8fc;
  margin: 4px 12px 8px 0;
  cursor: pointer;
}

#jdi-header .search-recommended-dropdown-layer .search-view .tags .tag:hover {
  color: var(--sys-color-Primary800);
  a {
    color: var(--sys-color-Primary800);
  }
}

#jdi-header .search-recommended-dropdown-layer .delete-history-btn {
  position: absolute;
  right: 16px;
  top: 18px;
  cursor: pointer;
}

#jdi-header .search-recommended-dropdown-layer .delete-history-btn:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header .search-bar .search-input {
  flex: 1;
  height: 38px;
  text-indent: 12px;
  font-size: 14px;
  border-radius: 6px;
  border: none;
  outline: none;
}

#jdi-header .search-bar .search-btn-wrap {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

#jdi-header .search-bar .search-btn {
  width: 58px;
  height: 32px;
  border-radius: 4px;
  background: var(--sys-Text-Primary);
  color: #fff;
  cursor: pointer;
}

#jdi-header .country-wrap {
  /* cursor: pointer; */
  position: relative;
  margin-right: 40px;
  color: var(--sys-Text-Primary);
}

#jdi-header.home .country-wrap {
  color: #fff;
}

/* #jdi-header .country-wrap:hover {
  color: var(--sys-color-Primary800);
  .country-list-dropdown-layer {
    display: block;
  }
  .arrow-down{
    transform: rotate(180deg);
  }
} */

/* #jdi-header.home .country-wrap:hover {
  color: #FFFFFFCC;
} */

#jdi-header .country-wrap>button {
  cursor: default;
}

#jdi-header .country-wrap .country-current {
  gap: 5px;
  font-size: 14px;
}

#jdi-header .country-list-dropdown-layer {
  width: 150px;
  border-radius: 4px;
  background: #fff;
  box-shadow:
    0 4px 28px 0 #0000000a,
    0 8px 24px 0 #0000000f;
  position: absolute;
  z-index: 2000;
  top: 50px;
  left: -50%;
  display: none;
}

#jdi-header .country-list-dropdown-layer:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 140px;
  height: 15px;
}

#jdi-header .country-list {
  padding: 4px 0px;
}

#jdi-header .country-list .country-list-item {
  height: 36px;
  line-height: 36px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  padding: 0 14px;
  color: var(--sys-Text-Primary);
}

#jdi-header .country-list .country-list-item button {
  color: var(--sys-Text-Primary);
}

#jdi-header .country-list .country-list-item:hover button {
  color: var(--sys-color-Primary800);
}

#jdi-header .header-main .me-btn-wrap {
  height: 100%;
  position: relative;
}

#jdi-header.home .header-main .me-btn-wrap {
  color: #fff;
}

#jdi-header.home .header-main .me-btn-wrap:hover {
  color: #ffffffcc;
}

#jdi-header .header-main .unlogin-btn,
#jdi-header .header-main .me-btn {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 11px 0;
  box-sizing: border-box;
  color: var(--sys-Text-Primary);
}

#jdi-header .header-main .unlogin-btn button,
#jdi-header .header-main .me-btn,
#jdi-header .header-main .me-btn a {
  display: flex;
  align-items: center;
}

#jdi-header.home .header-main .unlogin-btn button,
#jdi-header.home .header-main .me-btn,
#jdi-header.home .header-main .me-btn a {
  color: #fff;
}

#jdi-header.home .header-main .unlogin-btn button:hover,
#jdi-header.home .header-main .me-btn:hover,
#jdi-header.home .header-main .me-btn a:hover {
  color: #ffffffcc;
}

#jdi-header .info-box {
  color: #fff;
}

#jdi-header-sign-in-btn {
  display: flex;
  align-items: center;
}

#jdi-header-un-login:hover a {
  color: var(--sys-color-Primary800);
}

#jdi-header .header-main .me-btn:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header .header-main .me-icon {
  margin-right: 6px;
}

#jdi-header .header-main .me-btn-wrap:hover .me-list-dropdown-layer {
  display: block;
}

#jdi-header .me-list-dropdown-layer {
  width: 150px;
  border-radius: 4px;
  background: #fff;
  box-shadow:
    0 4px 28px 0 #0000000a,
    0 8px 24px 0 #0000000f;
  position: absolute;
  z-index: 2000;
  top: 50px;
  left: 0;
  display: none;
}

#jdi-header .me-list-dropdown-layer:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 140px;
  height: 15px;
}

#jdi-header .me-list .me-list-item {
  height: 40px;
  line-height: 40px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  padding: 0 14px;
  color: var(--sys-Text-Primary);
}

#jdi-header .me-list .me-list-item a {
  color: var(--sys-Text-Primary);
}

#jdi-header .me-list .me-list-item:hover a {
  color: var(--sys-color-Primary800);
}

#jdi-header .me-list .me-list-item .sign-out {
  color: #888b94;
}

#jdi-header .me-list #jdi-header-user-nickname {
  color: var(--sys-Text-Primary);
}

#jdi-header .me-list #jdi-header-user-nickname:hover {
  color: var(--sys-Text-Primary);
}

#jdi-header .me-list .me-list-item .sign-out:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header .me-list .me-list-item .me-avatar {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  vertical-align: top;
  margin-top: 5px;
  cursor: pointer;
}

#jdi-header .me-list .me-list-item .me-nickname {
  cursor: pointer;
}

#jdi-header .me-list .me-list-item .me-nickname:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header .top-btn-wrap {
  display: flex;
}

#jdi-header .top-btn-wrap a {
  position: relative;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: var(--sys-Text-Primary);
}

#jdi-header.home .top-btn-wrap a {
  color: var(--sys-Text-Primary);
}

#jdi-header .top-btn-wrap a:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header.home .top-btn-wrap a:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header.home .cart-wrap a {
  color: #fff;
}

#jdi-header.home .cart-wrap a:hover {
  color: #FFFFFFCC;
}

#jdi-header .top-btn-wrap .top-btn-icon {
  margin-right: 8px;
}

#jdi-header .top-btn-wrap>button {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

#jdi-header.home .top-btn-wrap>button {
  color: #fff;
}

#jdi-header.home .top-btn-wrap button:hover {
  color: #ffffffcc;
}

#jdi-header .top-btn-wrap>button {
  color: var(--sys-Text-Primary);
}

#jdi-header .top-btn-wrap>button:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header-cart-num {
  align-items: center;
  background: var(--sys-color-Primary800);
  border-radius: 100px;
  color: #fff;
  font-size: 10px;
  justify-content: center;
  top: 6px;
  left: 10px;
  max-width: 24px;
  min-width: 16px;
  padding: 0px 3px;
  position: absolute;
  text-align: center;
}

#jdi-header.home #jdi-header-cart-num {
  background: var(--sys-color-Primary700);
}

#jdi-header.home #jdi-header-cart-num {
  border: 1px solid #fff;
}

#jdi-header .header-main-nav-wrap {
  margin-top: -12px;
  padding-bottom: 4px;
}

#jdi-header .header-main-nav {
  display: flex;
  height: 40px;
  line-height: 40px;
}

#jdi-header .category {
  margin-right: 40px;
  position: relative;
}

#jdi-header .category .category-icon {
  vertical-align: sub;
  cursor: pointer;
  margin-right: 6px;
}

#jdi-header .category .category-arrow {
  vertical-align: sub;
  cursor: pointer;
  margin-left: 2px;
}

#jdi-header .category .category-btn {
  font-size: 14px;
  font-weight: 500;
  color: var(--sys-Text-Primary);
}

#jdi-header .category .category-btn .title {
  margin-left: 4px;
  cursor: pointer;
}

#jdi-header.home .category .category-btn {
  color: var(--sys-Text-Anti);
}

#jdi-header .category .category-btn:hover {
  color: var(--sys-color-Primary800);
}

#jdi-header.home .category .category-btn:hover {
  color: #ffffffcc;
}

#jdi-header .header-main-nav .nav-list {
  display: flex;
}

#jdi-header .header-main-nav .nav-list-item {
  margin-right: 40px;
  font-size: 16px;
}

#jdi-header .header-main-nav .nav-list-item a {
  font-size: 14px;
  color: var(--sys-Text-Primary);
}

#jdi-header .header-main-nav .nav-list-item:hover a {
  color: var(--sys-color-Primary800);
}

#jdi-header.home .header-main-nav .nav-list-item a {
  color: #ffffffcc;
}

#jdi-header.home .header-main-nav .nav-list-item:hover a {
  color: #ffffff;
}

#jdi-header .category:hover .category-list-lv1 {
  display: block;
}

#jdi-header .category .loading {
  background: #fff;
  padding-bottom: 12px;
}

#jdi-header .category:hover .loading {
  color: var(--sys-Text-Primary);
}

#jdi-header .category .category-list-lv1 .category-list-item {
  text-align: left;
}

#jdi-header .category .category-list-lv1 .category-list-item a {
  display: block;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 33px;
  color: var(--sys-Text-Primary);
  transition: 0.4s;
}

#jdi-header .category .category-btn:hover .category-arrow {
  transform: rotate(180deg);
}

#jdi-header .category .category-btn:hover .category-list-dropdown-layer {
  display: block;
}

#jdi-header .unsubscribe-txt {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sys-Text-Secondary);
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-right {
  margin-right: 40px;
}