#jdi-header .benefit-bar-wrap {
    background: #F5F6FA;
    height: 40px;
    line-height: 40px;
    min-width: 1088px;
}

#jdi-header.home .benefit-bar-wrap {
    background: #1A1A1A;
}

#jdi-header .benefit-bar-wrap .w {
    margin: 0 auto;
    min-width: 1008px;
    max-width: min(1866px, calc(100% - 80px));
}

#jdi-header .benefit-bar-wrap .benefit-bar {
    display: flex;
    height: 40px;
    gap: 40px;
}

#jdi-header .benefit-bar .tag-wrap {
    font-size: 14px;
}

#jdi-header .benefit-bar .tags-wrap {
    width: 100%;
    overflow: hidden;
}

#jdi-header .benefit-bar .tag-wrap .tags {
    width: 100%;
    height: 40px;
}

#jdi-header .benefit-bar .tag-wrap .tags,
.tag:hover {
    cursor: pointer;
}

#jdi-header .benefit-bar .tag-wrap:last-child {
    flex: 1;
    display: flex;
    justify-content: end;
}

#jdi-header .benefit-bar .tag-wrap .tag {
    display: flex;
    align-items: center;
    height: 40px;
    color: var(--sys-Text-Tertiary);
}

#jdi-header.home .benefit-bar .tag-wrap .tag {
    color: var(--sys-background-White);
}

.benefit-rich-content h1,
.benefit-rich-content h2,
.benefit-rich-content h3,
.benefit-rich-content h4,
.benefit-rich-content h5,
.benefit-rich-content h6,
.benefit-rich-content hr,
.benefit-rich-content p,
.benefit-rich-content pre,
.benefit-rich-content ol,
.benefit-rich-content ul,
.benefit-rich-content menu {
    all: revert;
    font-family: var(--font-family);
}

#jdi-header .benefit-bar .tag-wrap .tag .txt {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    line-height: 1;
    margin-left: 4px;
}

#jdi-header .benefit-bar .tag-wrap .tag img {
    margin-right: 6px;
}

#jdi-header .benefit-bar .tag-wrap .tag .download {
    color: var(--sys-Text-Primary);
}

#jdi-header.home .benefit-bar .tag-wrap .tag .download {
    color: var(--sys-Text-Anti);
}

#jdi-header .benefit-bar .tag-wrap .tag .title {
    font-size: 12px;
    margin-right: 6px;
    white-space: nowrap;
    color: var(--sys-Text-Primary);
}

.bold-weight {
    font-weight: bold;
}

#jdi-header.home .benefit-bar .tag-wrap .tag .title {
    color: #FFF;
}

#jdi-header .benefit-bar .tag-wrap .tag .sub-title {
    font-size: 12px;
    color: #888B94;
    white-space: nowrap;
}

#jdi-header.home .benefit-bar .tag-wrap .tag .sub-title {
    color: #FFFFFFCC;
}

#jdi-header .benefit-bar .download-wrap {
    position: relative;
    cursor: pointer;
}

#jdi-header .benefit-bar .download-wrap:hover .download-dropdown-layer {
    display: block;
}

#jdi-header .benefit-bar .download-dropdown-layer {
    padding: 32px 28px 8px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 4px 28px 0 #0000000A, 0 8px 24px 0 #0000000F;
    background: linear-gradient(180deg, #F9F2F2 0%, #FFFFFF 100%);
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 2000;
    display: none;
}

#jdi-header .benefit-bar .download-dropdown-layer .download-box {
    display: flex;
    margin-bottom: 20px;
}

#jdi-header .benefit-bar .download-dropdown-layer .left {
    width: 142px;
    margin-right: 24px;
}

#jdi-header .benefit-bar .download-dropdown-layer .left .down-load-btn {
    width: 142px;
    height: 56px;
    margin-bottom: 12px;
    display: block;
}

#jdi-header .benefit-bar .download-dropdown-layer:after {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 130px;
    height: 15px;
}

#jdi-header .benefit-bar .download-dropdown-layer .scan {
    width: 124px;
    height: 148px;
    background: #fff;
    line-height: 0;
    position: relative;
    top: -8px;
}

#jdi-header .benefit-bar .download-dropdown-layer .scan .txt {
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #888B94;
    justify-content: center;
}

#jdi-header .benefit-bar .download-dropdown-layer .scan .qrcode img {
    margin-top: 12px;
    margin-left: 12px;
    width: 100px;
    height: 100px;
}

#jdi-header .benefit-bar .download-dropdown-layer .mind {
    padding-top: 12px;
    border-top: 1px solid #0000000F;
}

#jdi-header .benefit-bar .download-dropdown-layer .mind .mind-item {
    height: 28px;
    line-height: 28px;
    margin-bottom: 12px;
    display: flex;
}

#jdi-header .benefit-bar .download-dropdown-layer .mind .mind-item .txt {
    margin-left: 12px;
    align-items: center;
}

#jdi-header .benefit-bar .download-dropdown-layer .mind .mind-item span {
    color: var(--sys-Text-Primary);
}